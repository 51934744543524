import { HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, finalize, tap, timeout } from 'rxjs/operators';
import { ProgressBarService } from '../services/progress-bar.service';
import { empty, EMPTY, Observable, throwError } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { StorageType } from '../enums/storage.enum';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

let blockerRequest = false;
let toastVisible = false;
export const httpInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  if (blockerRequest) {
    return EMPTY;
  }

  const toastService = inject(ToastrService);
  const progressBarService = inject(ProgressBarService);
  const storageService = inject(StorageService);
  const activatedRoute = inject(ActivatedRoute);
  const router = inject(Router);
  const hideLoaderRoutes = [
    'step1',
    'step2',
    'step3',
    'step4',
    'step5',
    'step6',
    'user/company',
  ];
  const showLoader = !hideLoaderRoutes.some((route) => req.url.includes(route));
  if (showLoader) {
    progressBarService.show();
  } else {
    progressBarService.hideAll();
  }

  const token = storageService.get(StorageType.TOKEN) ?? ''; // Provide a default value if null
  let clonedRequest;
  if (token !== '') {
    clonedRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    clonedRequest = req.clone();
  }

  return next(clonedRequest).pipe(
    catchError((error) => {
      if (error.status === 403) {
        const snapshot: any = activatedRoute.snapshot;
        if (
          !snapshot._routerState.url.includes('/active-account') &&
          !snapshot._routerState.url.includes('/create-guest-password') &&
          (snapshot._routerState.url.includes('/create-guest-password/') ||
            snapshot._routerState.url.includes('/activate-recuitre-account/'))
        ) {
          //storageService.clear()
          router.navigateByUrl(`/login`);
        }
        return EMPTY;
      } else if (error.status === 401) {
        blockerRequest = true;
        if (
          !error.url.includes('create-pass') &&
          !error.url.includes('refresh-token') &&
          !error.url.includes('/login')
        ) {
          router.navigateByUrl(`/login`).then(() => {
            // Una vez redirigido, desbloquea las peticiones
            blockerRequest = false;
          });
          if (!toastVisible) {
            toastVisible = true;
            toastService
              .error('Tu sesión a expirado', '', {
                timeOut: 3000,
              })
              .onHidden.subscribe(() => {
                toastVisible = false;
              });
          }
          storageService.clear();
        }
        if (error.url.includes('/login')) {
          blockerRequest = false;
          return throwError(error);
        } else {
          return EMPTY;
        }
      }

      // Re-lanza el error si no lo manejas
      return throwError(error);
    }),
    finalize(() => progressBarService.hideAll())
  );
};
