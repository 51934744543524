import { AiVacantComponent } from './dashboard/pages/vacant/aiVacant/aiVacant.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { MaintenanceComponent } from './public/pages/maintenance/maintenance.component';
import { KEYSYSTEM_SETTINGS } from './config/app-settings';
export const routes: Routes = [
  ...(KEYSYSTEM_SETTINGS.isInMaintenance && !localStorage.getItem('mnt-dev')
    ? [
        {
          path: '**',
          component: MaintenanceComponent,
        },
      ]
    : []),
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'public',
    loadComponent: () =>
      import('./public/pages/base/base.component').then((c) => c.BaseComponent),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./public/pages/login/login.component').then(
        (c) => c.LoginComponent
      ),
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./public/pages/register/register.component').then(
        (c) => c.RegisterComponent
      ),
  },
  {
    path: 'restore-password/:keyToken',
    loadComponent: () =>
      import(
        './generic/generic/password-recovery/password-recovery.component'
      ).then((c) => c.PasswordRecoveryComponent),
  },
  {
    path: 'restore-password',
    loadComponent: () =>
      import(
        './generic/generic/password-recovery/password-recovery.component'
      ).then((c) => c.PasswordRecoveryComponent),
  },
  {
    path: 'create-guest-password/:keyToken',
    redirectTo: '/onboarding/create-guest-password/:keyToken',
  },
  {
    path: 'onboarding',
    loadComponent: () =>
      import('./onboarding/onboarding.component').then(
        (c) => c.OnboardingComponent
      ),
    children: [
      {
        path: 'create-guest-password/:keyToken',
        loadComponent: () =>
          import('./onboarding/pages/aux-password/aux-password.component').then(
            (c) => c.AuxPasswordComponent
          ),
      },
      {
        path: 'email-verify',
        loadComponent: () =>
          import('./onboarding/pages/email-verify/email-verify.component').then(
            (c) => c.EmailVerifyComponent
          ),
      },
      {
        path: 'notifications',
        loadComponent: () =>
          import(
            './onboarding/pages/notifications/notifications.component'
          ).then((c) => c.NotificationsComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'basic-info',
        loadComponent: () =>
          import('./onboarding/pages/basic-info/basic-info.component').then(
            (c) => c.BasicInfoComponent
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'change-email',
    loadComponent: () =>
      import(
        './dashboard/pages/profile/email-verify/email-verify.component'
      ).then((c) => c.EmailVerifyComponent),
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        (c) => c.DashboardComponent
      ),
    canActivate: [AuthGuard],
    children: [
      {
        path: 'profile',
        loadComponent: () =>
          import('./dashboard/pages/profile/profile.component').then(
            (c) => c.ProfileComponent
          ),
      },
      {
        path: 'home',
        loadComponent: () =>
          import('./dashboard/pages/tablero/tablero.component').then(
            (c) => c.TableroComponent
          ),
      },
      {
        path: 'company-settings',
        loadComponent: () =>
          import(
            './dashboard/pages/profile/company-settings/company-settings.component'
          ).then((c) => c.CompanySettingsComponent),
      },
      {
        path: 'change-email',
        loadComponent: () =>
          import(
            './dashboard/pages/profile/change-email/change-email.component'
          ).then((c) => c.ChangeEmailComponent),
      },
      {
        path: 'change-password',
        loadComponent: () =>
          import(
            './dashboard/pages/profile/change-password/change-password.component'
          ).then((c) => c.ChangePasswordComponent),
      },
      {
        path: 'change-phone',
        loadComponent: () =>
          import(
            './dashboard/pages/profile/change-phone/change-phone.component'
          ).then((c) => c.ChangePhoneComponent),
      },
      {
        path: 'delete-account',
        loadComponent: () =>
          import(
            './dashboard/pages/profile/delete-account/delete-account.component'
          ).then((c) => c.DeleteAccountComponent),
      },
      {
        path: 'edit/:clientId/:pay-return',
        loadComponent: () =>
          import(
            './dashboard/pages/create-client/create-client.component'
          ).then((c) => c.CreateClientComponent),
      },
      {
        path: 'client/:clientId/:pay-return',
        loadComponent: () =>
          import(
            './dashboard/pages/create-client/create-client.component'
          ).then((c) => c.CreateClientComponent),
      },
      {
        path: 'client/:clientId',
        loadComponent: () =>
          import(
            './dashboard/pages/create-client/create-client.component'
          ).then((c) => c.CreateClientComponent),
      },
      {
        path: 'client',
        loadComponent: () =>
          import(
            './dashboard/pages/create-client/create-client.component'
          ).then((c) => c.CreateClientComponent),
      },
      {
        path: 'clients',
        loadComponent: () =>
          import('./dashboard/pages/clients/clients.component').then(
            (c) => c.ClientsComponent
          ),
      },
      {
        path: 'plans/:clientId',
        loadComponent: () =>
          import('./dashboard/pages/plans/plans.component').then(
            (c) => c.PlansComponent
          ),
      },
      {
        path: 'plans',
        loadComponent: () =>
          import('./dashboard/pages/plans/plans.component').then(
            (c) => c.PlansComponent
          ),
      },
      {
        path: 'pay-methods',
        loadComponent: () =>
          import(
            './dashboard/pages/payment-methods/payment-methods.component'
          ).then((c) => c.PaymentMethodsComponent),
      },
      {
        path: 'payment-history',
        loadComponent: () =>
          import(
            './dashboard/pages/payment-history/payment-history.component'
          ).then((c) => c.PaymentHistoryComponent),
      },
      {
        path: 'recruiting-team',
        loadComponent: () =>
          import(
            './dashboard/pages/recruiting-team/recruiting-team.component'
          ).then((c) => c.RecruitingTeamComponent),
      },
      {
        path: 'candidates-vector/:type/:vacancyId',
        loadComponent: () =>
          import(
            './dashboard/pages/candidates/candidates-vector/candidates-vector.component'
          ).then((c) => c.CandidatesVectorComponent),
      },
      {
        path: 'candidate-detail/:id',
        loadComponent: () =>
          import(
            './dashboard/pages/candidates/candidate-detail/candidate-detail.component'
          ).then((c) => c.CandidateDetailComponent),
      },
      {
        path: 'vacancy',
        children: [
          {
            path: 'ai',
            loadComponent: () =>
              import(
                './dashboard/pages/vacant/aiVacant/aiVacant.component'
              ).then((c) => c.AiVacantComponent),
          },
          {
            path: 'vector',
            loadComponent: () =>
              import(
                './dashboard/pages/vacant/vacants-vector-active/vacants-vector-active.component'
              ).then((c) => c.VacantsVectorActiveComponent),
          },
          {
            path: 'vector/:type',
            loadComponent: () =>
              import(
                './dashboard/pages/vacant/vacants-vector-active/vacants-vector-active.component'
              ).then((c) => c.VacantsVectorActiveComponent),
          },
          {
            path: 'step1/:vacancyId',
            loadComponent: () =>
              import('./dashboard/pages/vacant/step1/step1.component').then(
                (c) => c.Step1Component
              ),
          },
          {
            path: 'step1',
            loadComponent: () =>
              import('./dashboard/pages/vacant/step1/step1.component').then(
                (c) => c.Step1Component
              ),
          },
          {
            path: 'step2/:vacancyId',
            loadComponent: () =>
              import('./dashboard/pages/vacant/step2/step2.component').then(
                (c) => c.Step2Component
              ),
          },
          {
            path: 'step3/:vacancyId',
            loadComponent: () =>
              import('./dashboard/pages/vacant/step3/step3.component').then(
                (c) => c.Step3Component
              ),
          },
          {
            path: 'step4/:vacancyId',
            loadComponent: () =>
              import('./dashboard/pages/vacant/step4/step4.component').then(
                (c) => c.Step4Component
              ),
          },
          {
            path: 'step5/:vacancyId',
            loadComponent: () =>
              import('./dashboard/pages/vacant/step5/step5.component').then(
                (c) => c.Step5Component
              ),
          },
          {
            path: 'step6/:vacancyId',
            loadComponent: () =>
              import('./dashboard/pages/vacant/step6/step6.component').then(
                (c) => c.Step6Component
              ),
          },
          {
            path: 'tips/:vacancyId',
            loadComponent: () =>
              import('./dashboard/pages/vacant/tips/tips.component').then(
                (c) => c.TipsComponent
              ),
          },
          {
            path: 'preview/:vacancyId/:type',
            loadComponent: () =>
              import('./dashboard/pages/vacant/preview/preview.component').then(
                (c) => c.PreviewComponent
              ),
          },
          { path: '**', redirectTo: 'vector' },
        ],
      },
      { path: '**', redirectTo: 'vacancy' },
    ],
  },
  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full',
  },
];
